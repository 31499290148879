import React from 'react'
import { BookOpen, Calendar } from 'lucide-react';


function Blogcard({blogPosts}) {
  return (
    <div className="bg-transparent py-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid gap-8 sm:grid-cols-1 lg:grid-cols-2">
          {blogPosts.map((post) => (
            <a href={post.link}>
              <div
              key={post.id}
              className="relative flex flex-col md:flex-row overflow-hidden bg-white rounded-3xl shadow-lg transition-all transform hover:scale-105 hover:shadow-xl"
            >
              {/* Image Section */}
              <div
                className="md:w-1/2 h-48 sm:h-56 md:h-auto bg-cover bg-center"
                style={{ backgroundImage: `url(${post.image})` }}
              ></div>

              {/* Content Section */}
              <div className="flex-1 p-6 sm:p-8 flex flex-col justify-between">
                <div>

                  <h2 className="text-2xl font-bold text-gray-800 mb-4 hover:text-red-600 transition">
                    {post.title}
                  </h2>
                  <p className="text-gray-600 text-sm mb-6 leading-relaxed">{post.excerpt}</p>
                </div>

                <div className="flex justify-between items-center text-sm text-gray-500">
                  <div className=" flex flex-col items-center space-x-1">

                    <p className='flex'>
                      <Calendar size={16} />
                    <span>{post.date}</span>
                    </p>
                  </div>
                </div>
              </div>

              {/* Call-to-Action Button */}
              <div className="absolute bottom-4 right-4">
                <button className="px-5 py-2 text-sm font-semibold text-white bg-red-600 rounded-full shadow-lg hover:bg-red-700 transition">
                  <div className="flex items-center space-x-2">
                    <BookOpen size={16} />
                    <span>Read</span>
                  </div>
                </button>
              </div>
            </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Blogcard