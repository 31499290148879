import React from "react";
import blogData from "./blogdata";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const BlogPost = () => {

    const {link} = useParams()
    const data = blogData[link]

    const highlightWords = (text, wordsToBold) => {
        const regex = new RegExp(
          `(${wordsToBold.map(phrase => phrase.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")).join("|")})`,
          "gi"
        );
        const parts = text.split(regex);
      
        return parts.map((part, index) =>
          wordsToBold.some(word => word.toLowerCase() === part.toLowerCase()) ? (
            <strong className="font-extrabold text-lg" key={index}>{part}</strong>
          ) : (
            part
          )
        );
      };

    return (
        <>
            {/* Header Section */}
            <div className='w-full h-[40vh] md:h-[380px] flex flex-col' style={{
                backgroundImage: `url("https://d2ehq5aws28ia0.cloudfront.net/himalayan/1.jpg")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <div className=' h-full w-full bg-black/15 items-center justify-center flex flex-col'>

                    {/* Helmet for SEO */}
                    <Helmet>
                        <title>{data.meta}</title>
                        <meta
                            name="description"
                            content={data.description}
                        />
                    </Helmet>
                    <div className=' h-full w-full items-center justify-center flex flex-col'>
                    <div className='w-full h-full flex flex-col items-center justify-center'>
                        <div className='text-center text-2xl mt-[70px] instrument text-white
            '>
                            <span className='md:text-6xl text-4xl lg:text-8xl pattaya'>Blogs</span>
                        </div>
                    </div>

                </div>

                </div>
            </div>
            <div className="container mx-auto" style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
                <h1 className="text-5xl text-red-600 pattaya text-center my-5">{data.title}</h1>
                <div className=' my-4 conatiner overflow-hidden w-[80%] mx-auto'>
                    <img className='object-cover hover:scale-105 transition-all duration-500' src={data.image}
                        alt={data.alt} />
                </div>
                {data.sections.map((section, index) => (
                    <section className="text-center" key={index}>
                        {section.heading && <h2 className="text-3xl pattaya text-red-600 my-3" >{section.heading}</h2>}
                        <p className="caudex text-justify">{section.content.split("*").map((paragraph, index) => (
                            <p key={index} className="caudex text-justify mb-3">
                                {highlightWords(paragraph.trim(), data.wordsToBold)}
                            </p>
                        ))}</p>
                    </section>
                ))}
            </div>
        </>
    );
};

export default BlogPost;
