import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { 
  CalendarCheck, 
  User, 
  MapPin, 
  Bed, 
  Mail, 
  Phone, 
  Check 
} from 'lucide-react';

function BookingConfirmation({ data }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [error, setError] = useState(null); // For error handling

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Format dates
    const formattedFromDate = formatDate(data.fromDate);
    const formattedToDate = formatDate(data.toDate);
  
    const bookingDetails = {
      name: contactDetails.name,
      email: contactDetails.email,
      phone: contactDetails.phone,
      details: JSON.stringify({
        checkIN: formattedFromDate,
        checkOut: formattedToDate,
        roomType: data.title,
        Guests: data.adults,
        extrabed: data.extrabed,
      }),
    };
  
    console.log('Formatted Booking Details:', bookingDetails);
  
    // Email details
    // const emailDetails = {
    //   to: contactDetails.email,
    //   subject: 'Booking Confirmation',
    //   body: `Hi ${contactDetails.name},\n\nYour booking from ${formattedFromDate} to ${formattedToDate} for a ${data.title} room has been confirmed.\nGuests: ${data.adults}, Extra Bed: ${data.extrabed ? 'Yes' : 'No'}.\n\nThank you!`,
    // };
  
    try {
      // Submit booking details
      const bookingResponse = await axios.post(
        'https://account.solidperformers.com/leadsapi/data/205c06f43aac35c251296c7cc1a4f68a',
        bookingDetails,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      // console.log('Booking API Response:', bookingResponse.data);
  
      // // Send email
      // const emailResponse = await axios.post(
      //   'https://nodemailer-ochre.vercel.app/send-email',
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   }
      // );
  
      // console.log('Email API Response:',);
  
      // Update state
      setIsSubmitted(true);
    } catch (err) {
      console.error('Error:', err);
      setError(err.response?.data?.message || err.message);
    }
  };
  
  

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-IN', {
      year: 'numeric', 
      month: 'short', 
      day: 'numeric'
    });
  };

  return (
    <div className="relative w-full h-full flex items-center justify-center py-8 px-4 sm:px-6 md:px-8">
      <div className="absolute inset-0 bg-gradient-to-br opacity-50 pointer-events-none"></div>
      
      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="relative z-10 w-full max-w-4xl grid grid-cols-1 sm:grid-cols-2 bg-white rounded-3xl shadow-2xl overflow-hidden"
      >
        {/* Left Side - Booking Details */}
        <div className="bg-red-600 text-white p-6 sm:p-8 flex flex-col justify-between relative">
          <div>
            <div className="flex items-center mb-4">
              <CalendarCheck className="mr-3" size={28} />
              <h2 className="text-xl sm:text-2xl font-bold">Booking Details</h2>
            </div>

            <div className="space-y-3 sm:space-y-4">
              <div className="flex items-center">
                <MapPin className="mr-3" size={20} />
                <div>
                  <p className="text-sm opacity-75">Stay</p>
                  <p className="font-semibold">{data.title}</p>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 sm:gap-4">
                <div>
                  <p className="text-sm opacity-75">Check-In</p>
                  <p className="font-semibold">{formatDate(data.fromDate)}</p>
                </div>
                <div>
                  <p className="text-sm opacity-75">Check-Out</p>
                  <p className="font-semibold">{formatDate(data.toDate)}</p>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 sm:gap-4">
                <div>
                  <p className="text-sm opacity-75">Guests</p>
                  <p className="font-semibold">{data.adults}</p>
                </div>
                {data.extrabed > 0 && (
                  <div>
                    <p className="text-sm opacity-75">Extra Beds</p>
                    <p className="font-semibold">{data.extrabed}</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Decorative elements */}
          <div className="absolute bottom-0 right-0 opacity-10">
            <Bed size={180} />
          </div>
        </div>

        {/* Right Side - Contact Form */}
        <div className="p-6 sm:p-8 flex flex-col justify-center">
          {!isSubmitted ? (
            <>
              <h3 className="text-xl sm:text-2xl font-bold text-red-800 mb-4 text-center">
                Reservation
              </h3>

              <form onSubmit={handleSubmit} className="space-y-4">
                {/* Name Input */}
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="text-red-500" size={20} />
                  </div>
                  <input
                    type="text"
                    name="name"
                    required
                    value={contactDetails.name}
                    onChange={handleInputChange}
                    placeholder="Full Name"
                    className="w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 transition-all duration-300"
                  />
                </div>

                {/* Email Input */}
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail className="text-red-500" size={20} />
                  </div>
                  <input
                    type="email"
                    name="email"
                    required
                    value={contactDetails.email}
                    onChange={handleInputChange}
                    placeholder="Email Address"
                    className="w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 transition-all duration-300"
                  />
                </div>

                {/* Phone Input */}
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Phone className="text-red-500" size={20} />
                  </div>
                  <input
                    type="tel"
                    name="phone"
                    required
                    value={contactDetails.phone}
                    onChange={handleInputChange}
                    placeholder="Phone Number"
                    className="w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 transition-all duration-300"
                  />
                </div>

                {/* Error Message */}
                {error && <p className="text-red-500 text-center">{error}</p>}

                {/* Submit Button */}
                <button
                  type="submit"
                  className="w-full bg-red-600 text-white py-3 rounded-lg hover:bg-red-700 transition-colors duration-300 flex items-center justify-center space-x-2 shadow-md hover:shadow-lg mt-4"
                >
                  <Check size={20} />
                  <span>Reserve</span>
                </button>
              </form>
            </>
          ) : (
            <div className="text-center">
              <Check size={64} className="mx-auto mb-4 text-green-500" />
              <h2 className="text-2xl sm:text-3xl font-bold text-red-800 mb-4">Query Confirmed!</h2>
              <p className="text-gray-600 mb-6">
                Thanks for showing interest in our stay. Our executive will contact you within 24 hours.
              </p>
              <a href='/'>
                <button
                  onClick={() => setIsSubmitted(false)}
                  className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition-colors shadow-md"
                >
                  Make Another Reservation
                </button>
              </a>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
}

export default BookingConfirmation;
