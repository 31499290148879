import React, { useState, useRef, useCallback } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

function Cardcarousel({data}) {
    const images = data.images
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);

    const nextSlide = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, [images.length]);

    const prevSlide = useCallback(() => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    }, [images.length]);

    return (
        <div ref={sliderRef} className="h-full mx-auto select-none">
            <div className="h-full w-full relative overflow-hidden rounded-t-xl">
                {images.map((image, index) => (
                    <a href={`/cottages/${data.title}`} key={index}>
                        <img
                        src={image}
                        alt={data.alt}
                        draggable="false"
                        className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-500 ${
                            index === currentIndex ? 'opacity-100' : 'opacity-0'
                        }`}
                    />
                    </a>
                ))}

                {/* Navigation Buttons */}
                <button
                    onClick={prevSlide}
                    className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/50 rounded-full p-1 hover:bg-white/75 transition z-10"
                >
                    <ChevronLeft className="text-black" size={24} />
                </button>
                <button
                    onClick={nextSlide}
                    className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/50 rounded-full p-1 hover:bg-white/75 transition z-10"
                >
                    <ChevronRight className="text-black" size={24} />
                </button>

                {/* Dot Indicators */}
                <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`h-2 w-2 rounded-full transition-all cursor-pointer ${
                                index === currentIndex
                                    ? 'bg-white scale-125'
                                    : 'bg-white/50 hover:bg-white/75'
                            }`}
                            onClick={() => setCurrentIndex(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Cardcarousel;