import Marquee from "react-fast-marquee";
import "./style.css";

const IvmCarousel = () => {
  const CAROUSEL_SPEED = 100;
  const CAROUSEL_DATA = [
    {
      imageSrc: "https://d6pltnqzug32h.cloudfront.net/caros/1.jpg",
      title: "Mukteshwar nainital",
    },
    {
      imageSrc: "https://d6pltnqzug32h.cloudfront.net/caros/2.jpg",
      title: "Stay in mukteshwar",
    },
    {
      imageSrc: "https://d6pltnqzug32h.cloudfront.net/caros/3.jpg",

      title: "apartment stay",
    },
    {
      imageSrc: "https://d6pltnqzug32h.cloudfront.net/caros/4.jpg",
      title: "Resort in mukteshwar",
    },
    {
      imageSrc: "https://d6pltnqzug32h.cloudfront.net/caros/5.jpg",
      title: "snowfall in mukteshwar nainital",
    },
    {
      imageSrc: "https://d6pltnqzug32h.cloudfront.net/caros/7.jpg",
      title: "homestay near nainital",
    },
    
    {
      imageSrc: "https://d6pltnqzug32h.cloudfront.net/caros/6.jpg",
      title: "snowfall in indus valley homestay",
    },
  ];

  const renderCarouselSlides = () => (
    <div className="ivmCarousel__subContainer">
      {CAROUSEL_DATA.map((res, index) => {
        const { imageSrc, title } = res;
        return (
          <div className="ivmCarousel__slide" key={index}>
            <img className="ivmCarousel__image" src={imageSrc} alt={title} />
          </div>
        );
      })}
    </div>
  );

  return (
    <Marquee className="ivmCarousel__container bg-white" speed={CAROUSEL_SPEED}>
      {renderCarouselSlides()}
    </Marquee>
  );
};

export default IvmCarousel;