import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { BiSupport } from "react-icons/bi";
import ivh1 from "../asset/ivh3.png";
import ContactUs from './ContactUs';
import { X } from 'lucide-react';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const navItems = [
        { name: "Home", href: "/", description: "Welcome Home" },
        { name: "About", href: "/about", description: "Our Story" },
        { name: "Dine In", href: "/dinein", description: "Culinary Delights" },
        { name: "Nearby", href: "/nearby", description: "Explore Around" },
        { name: "Blogs", href: "/blogs", description: "Travel Insights" }
    ];

    // Menu variants for animations
    const menuVariants = {
        hidden: {
            opacity: 0,
            x: "100%",
            transition: {
                type: "tween",
                duration: 0.4
            }
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                type: "tween",
                duration: 0.4
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: (index) => ({
            opacity: 1,
            x: 0,
            transition: {
                delay: index * 0.1,
                type: "spring",
                stiffness: 300
            }
        })
    };

    // Disable body scrolling when contact popup is open
    useEffect(() => {
        document.body.style.overflow = isContactOpen ? 'hidden' : 'auto';
        return () => (document.body.style.overflow = 'auto');
    }, [isContactOpen]);

    useEffect(() => {
        document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
        return () => (document.body.style.overflow = 'auto');
    }, [isMenuOpen]);


    // Scroll and resize effects
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const toggleContact = () => setIsContactOpen(!isContactOpen);

    return (
        <motion.nav
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            className={`w-full h-[80px] md:h-[100px] border-b-[1px] glass ${isMenuOpen ? "fixed" : "absolute"} top-0 left-0 z-40 border-white ${isScrolled ? 'bg-white/70 shadow-md' : ''} transition-all duration-300`}
        >
            <div className="container px-4 md:px-0 md:w-full mx-auto flex items-center justify-between w-[90%] h-full">
                <motion.a
                    href='/'
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <div className='flex justify-center items-center p-3 rounded-md'>
                        <img 
                            className='h-14 md:h-16' 
                            src={ivh1} 
                            alt="" 
                        />
                    </div>
                </motion.a>

                <div className='flex gap-3 items-center'>
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={`text-xs hidden md:flex items-center gap-2 rounded-full cursor-pointer hover:bg-red-700 text-white bg-red-600 p-2 md:p-3`}
                        onClick={toggleContact}
                    >
                        <BiSupport size={20} color='white' />
                        <span className="">Contact Us</span>
                    </motion.div>

                    <motion.div
                        className='menuu z-50'
                        onClick={toggleMenu}
                        whileHover={{ rotate: 0 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        {/* Hamburger menu SVG */}
                        <svg
                            className={`ham hamRotate ham8 ${isMenuOpen ? 'active' : ''}`}
                            viewBox="0 0 100 100"
                            width="60"
                        >
                            <path
                                className="line top"
                                d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                            <path
                                className="line middle"
                                d="m 30,50 h 40" />
                            <path
                                className="line bottom"
                                d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                        </svg>
                    </motion.div>

                    <AnimatePresence>
                        {isMenuOpen && (
                            <motion.div
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                                variants={menuVariants}
                                className="fixed right-0 top-0 h-[100vh] w-full bg-white/60 flex justify-end"
                            >
                                <div className="flex flex-col justify-start w-[350px] glass md:mt-[0] items-center h-full shadow-2xl relative">
                                    <motion.ul
                                        initial="hidden"
                                        animate="visible"
                                        className="text-xl mt-[25%] md:mt-[30%] w-full px-10"
                                    >
                                        {navItems.map((item, index) => (
                                            <motion.li
                                                key={index}
                                                custom={index}
                                                variants={itemVariants}
                                                initial="hidden"
                                                animate="visible"
                                                className="group"
                                            >
                                                <a
                                                    href={item.href}
                                                    className="block py-4 px-5 transition-all duration-300 text-center relative overflow-hidden"
                                                >
                                                    <span className="block text-gray-800 group-hover:text-red-600 transition-colors">
                                                        {item.name}
                                                    </span>
                                                    <span className="block text-xs text-gray-500 opacity-0 group-hover:opacity-100 transition-all duration-300 transform translate-y-2 group-hover:translate-y-0">
                                                        {item.description}
                                                    </span>
                                                    <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-red-600 transition-all duration-300 group-hover:w-full"></span>
                                                </a>
                                            </motion.li>
                                        ))}
                                    </motion.ul>
                                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={`text-xs flex md:hidden mt-[10px] items-center gap-2 rounded-full cursor-pointer hover:bg-red-700 text-white bg-red-600 p-2 md:p-3`}
                        onClick={toggleContact}
                    >
                        <BiSupport size={20} color='white' />
                        <span className="">Contact Us</span>
                    </motion.div>
                                </div>
                                
                            </motion.div>
                            
                        )}
                    </AnimatePresence>

                    {/* Contact Us Popup */}
                    <AnimatePresence>
                        {isContactOpen && (
                            <div className="fixed top-0 mx-auto z-50 left-0 bg-gray-200/95 h-[100vh] w-full p-8">
                                <button
                                    onClick={toggleContact}
                                    className="fixed z-40 top-6 right-6 text-gray-700 bg-gray-300 hover:bg-gray-400 p-3 rounded-full shadow-lg"
                                >
                                    <X size={24} />
                                </button>
                                <div className='container mx-auto h-full w-full'>
                                    <ContactUs />
                                </div>
                            </div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </motion.nav>
    );
};

export default Navbar;