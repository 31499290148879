import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Calendar from './Calendar'; // Ensure Calendar component exists
import { X } from 'lucide-react';


function MobileBook() {
    const navigate = useNavigate();

    // Set the default check-in to current date and check-out to tomorrow
    const currentDate = new Date();
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(currentDate.getDate() + 1);

    const [bookingData, setBookingData] = useState({
        checkIn: currentDate,
        checkOut: tomorrowDate,
        guests: 1
    });

    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [hoverDate, setHoverDate] = useState(null);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [currentCalendarMode, setCurrentCalendarMode] = useState('checkIn');
    const [isFormVisible, setIsFormVisible] = useState(false); // State to control form visibility

    const generateCalendarDays = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const startingDay = firstDay.getDay();
        const days = [];

        for (let i = 0; i < startingDay; i++) {
            days.push(null);
        }

        for (let i = 1; i <= lastDay.getDate(); i++) {
            days.push(new Date(year, month, i));
        }

        return days;
    };

    const [calendarDays, setCalendarDays] = useState(generateCalendarDays(currentMonth));

    const changeMonth = (increment) => {
        const newMonth = new Date(currentMonth);
        newMonth.setMonth(newMonth.getMonth() + increment);
        setCurrentMonth(newMonth);
        setCalendarDays(generateCalendarDays(newMonth));
    };

    const formatDate = (date) => {
        if (!date) return 'Add dates';
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric'
        });
    };

    const isDateInRange = (date) => {
        if (!bookingData.checkIn) return false;
        const checkOutDate = bookingData.checkOut || hoverDate;
        if (!checkOutDate) return false;

        const start = bookingData.checkIn < checkOutDate 
            ? bookingData.checkIn 
            : checkOutDate;
        const end = bookingData.checkIn < checkOutDate 
            ? checkOutDate 
            : bookingData.checkIn;

        return date > start && date < end;
    };

    const handleCheckInSelect = (date) => {
        const defaultCheckOut = new Date(date);
        defaultCheckOut.setDate(date.getDate() + 1);

        setBookingData(prev => ({
            ...prev,
            checkIn: date,
            checkOut: defaultCheckOut
        }));

        setCurrentCalendarMode('checkOut');
    };

    const handleCheckOutSelect = (date) => {
        if (date <= bookingData.checkIn) {
            alert("Check-out date must be after check-in date.");
            return;
        }

        setBookingData(prev => ({
            ...prev,
            checkOut: date
        }));

        setIsCalendarVisible(false);
    };

    const handleSearch = () => {
        if (!bookingData.checkIn || !bookingData.checkOut) {
            alert("Please select both check-in and check-out dates");
            return;
        }

        navigate('/availability', { state: bookingData });
    };

    return (
        <div className="">
            <div className="max-w-md mx-auto">
                {/* Book Button */}
                <button
                    onClick={() => setIsFormVisible(true)}
                    className="w-full bg-red-600 text-white py-4 px-6 rounded-lg text-lg font-semibold hover:bg-red-700 transition-colors mb-6"
                >
                    Book Now
                </button>

                {/* Calendar Modal */}
                {isFormVisible && (
                    <div className="fixed w-full h-full inset-0 bg-black/50 z-50 flex justify-center items-center p-5" >
                        <div className="bg-white w-full max-w-md mt-[80px] rounded-lg p-6 relative">
                            <h2 className="text-2xl font-semibold mb-4 text-center">Book Your Stay</h2>

                            {/* Date Selection */}
                            <div className="space-y-4 mb-6">
                                <div 
                                    onClick={() => {
                                        setCurrentCalendarMode('checkIn');
                                        setIsCalendarVisible(true);
                                    }}
                                    className="bg-gray-100 p-4 rounded-lg cursor-pointer"
                                >
                                    <label className="block text-sm text-gray-600 mb-1">
                                        Check-in
                                    </label>
                                    <p className="text-lg font-semibold">
                                        {formatDate(bookingData.checkIn)}
                                    </p>
                                </div>

                                <div 
                                    onClick={() => {
                                        if (bookingData.checkIn) {
                                            setCurrentCalendarMode('checkOut');
                                            setIsCalendarVisible(true);
                                        } else {
                                            alert("Please select check-in date first");
                                        }
                                    }}
                                    className="bg-gray-100 p-4 rounded-lg cursor-pointer"
                                >
                                    <label className="block text-sm text-gray-600 mb-1">
                                        Check-out
                                    </label>
                                    <p className="text-lg font-semibold">
                                        {formatDate(bookingData.checkOut)}
                                    </p>
                                </div>
                            </div>

                            {/* Guests */}
                            <div className="bg-gray-100 p-4 rounded-lg mb-6">
                                <label className="block text-sm text-gray-600 mb-1">
                                    Guests
                                </label>
                                <div className="flex items-center justify-between">
                                    <span className="text-lg font-semibold">
                                        {bookingData.guests} Guest{bookingData.guests > 1 ? 's' : ''}
                                    </span>
                                    <div className="flex space-x-4 items-center">
                                        <button 
                                            onClick={() => setBookingData(prev => ({
                                                ...prev,
                                                guests: Math.max(1, prev.guests - 1)
                                            }))}
                                            className="bg-gray-200 w-10 h-10 rounded-full"
                                        >
                                            -
                                        </button>
                                        <span>{bookingData.guests}</span>
                                        <button 
                                            onClick={() => setBookingData(prev => ({
                                                ...prev,
                                                guests: Math.min(8, prev.guests + 1)
                                            }))}
                                            className="bg-gray-200 w-10 h-10 rounded-full"
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Search Button */}
                            <button 
                                onClick={handleSearch}
                                className="w-full bg-red-600 text-white py-4 rounded-lg text-lg font-semibold hover:bg-red-700 transition-colors mb-4"
                            >
                                Search
                            </button>

                            {/* Close Button */}
                            <button 
                                onClick={() => setIsFormVisible(false)}
                                className="w-full bg-gray-100 py-3 rounded-lg"
                            >
                                Close
                            </button>

                            {/* Calendar */}
                            {isCalendarVisible && (
                                <div className="absolute w-full h-full inset-0 bg-white/80 z-50 flex justify-center items-center">
                                    <div className="relative h-full w-full max-w-md">
                                    <button
                                    onClick={()=>{setIsCalendarVisible(false)}}
                                    className="absolute z-50 top-12 right-6 text-gray-700 bg-gray-300 hover:bg-gray-400 p-3 rounded-full shadow-lg"
                                >
                                    <X size={24} />
                                </button>
                                        <div className="flex w-full h-full items-center justify-center mt-8">
                                            <Calendar 
                                                currentMonth={currentMonth}
                                                changeMonth={changeMonth}
                                                calendarDays={calendarDays}
                                                bookingData={bookingData}
                                                hoverDate={hoverDate}
                                                isCheckIn={currentCalendarMode === 'checkIn'}
                                                formatDate={formatDate}
                                                isDateInRange={isDateInRange}
                                                handleCheckInSelect={handleCheckInSelect}
                                                handleCheckOutSelect={handleCheckOutSelect}
                                                setHoverDate={setHoverDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MobileBook;
