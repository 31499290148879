import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center h-[70vh] bg-transparent">
      <div className="text-center p-6 ">
        <h1 className="text-8xl font-extrabold text-gray-800 drop-shadow-md mb-2">404</h1>
        <h2 className="text-2xl text-gray-700 font-semibold mb-4 tracking-wide">
          Oops! Page Not Found
        </h2>
        <p className="text-lg text-gray-600 mb-8 max-w-md">
          The page you're looking for doesn't exist, or it might have been moved. Let's get you back!
        </p>
        <div className="flex space-x-4 justify-center">
          <button
            onClick={() => navigate(-1)}
            className="px-6 py-3 bg-gray-800 text-white font-medium rounded-full shadow-md hover:bg-gray-700 transition-all duration-300"
          >
            Go Back
          </button>
          <button
            onClick={() => navigate("/")}
            className="px-6 py-3 bg-red-600 text-white font-medium rounded-full shadow-md hover:bg-red-500 transition-all duration-300"
          >
            Go to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
