
export const cottageData = {
  "1-bhk-apartment": {
    title: "1 BHK Apartment",
    plot: "IVH 69",
    maxguest: 2,
    extbed: 1,
    alt:[
      "1BHK apartment stay",
      "homestay in mukteshwar nainital"
    ],
    images: [
      "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/7.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/8.jpg",
    ],
    meta: "Book a Comfortable 1 BHK Apartment in Mukteshwar",
    description: "Indus Valley Homestay offers a beautiful 1 BHK apartment in Mukteshwar for stay, perfect for a peaceful and private stay surrounded by nature",
    summary: "",
    price: "4000",
  },
  "studio-apartment": {
    title: "Studio Apartment",
    plot: "IVH 69 - F1",
    maxguest: 2,
    extbed: 1,
    alt:[
      "Studio apartment in mukteshwar nainital",
      "hotel in mukteshwar nainital"
    ],
    images: [

      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/7.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/8.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/6.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/himalayan/1.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/1.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/2.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/3.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg",

    ],
    meta: "Cozy Studio Apartment in Mukteshwar | Indus Valley Homestay",
    description: "Book a cozy and modern studio apartment at Indus Valley Homestay in Mukteshwar. Enjoy comfort and stunning mountain views",
    summary: "",
    price: "2000",
  },
  "studio-apartment-for-stay": {
    title: "Studio Apartment",
    plot: "IVH 69 - F2",
    alt:[
      "resort in mukteshwar nainital",
      "hotel for stay in mukteshwar"
    ],
    maxguest: 2,
    extbed: 1,
    images: [

      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/7.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/8.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/6.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/himalayan/1.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/1.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/2.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/3.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg",

    ],
    summary: "",
    meta: "Contact Us for Studio Apartment in Mukteshwar To Stay",
    description: "Stay in Studio Apartment at Indus Valley Homestay, Mukteshwar. Enjoy modern amenities and breathtaking views in a peaceful environment",
    price: "2000",
  },
  "studio-apartment-f4": {
    title: "Studio Apartment",
    plot: "IVH 69 - F4",
    alt:[
      "resort near nainital",
      "apartments for stay"
    ],
    maxguest: 2,
    extbed: 1,
    images: [
      "https://d2ehq5aws28ia0.cloudfront.net/project/5.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/7.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/8.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/6.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/5.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/1.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/2.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/3.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/f2/4.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/3.jpg",
    ],
    summary: "",
    meta: "Book Studio Apartment for a Peaceful Stay in Mukteshwar",
    description: "We offer a cozy studio apartment in Mukteshwar. A perfect blend of comfort and nature at Indus Valley Homestay, ideal for a peaceful stay",
    price: "2000",
  },
  "2-bhk-villa": {
    title: "2 BHK Villa",
    maxguest: 4,
    extbed: 2,
    plot: "IVH 47",
    meta: "Peaceful 2 BHK Villa | Rental Cottage in Mukteshwar",
    description: "Stay in a comfortable 2 BHK villa at Indus Valley Homestay, Mukteshwar. Enjoy a relaxing family vacation with modern amenities and nature views",
    alt:[
      "2bhk villa homestay in mukteshwar",
      "mountains in mukteshwar"
    ],
    images: [
      "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/himalayan/6.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/6.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/project/21.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/10.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/11.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/2.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/3.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/4.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/cot47/7.jpg",

    ],
    summary: "Escape to our 2BHK Nanda Devi View Villa - a perfect blend of modern comfort and breathtaking nature. Wake up to panoramic views, our 2 BHK family room features 2 bedrooms and a separate bathroom with a shower, and living area and a dining area with a kitchenette, a wardrobe, an electric kettle as well as a garden with mountain views. relax in spacious living areas, and indulge in the serenity of your private haven, you can enjoy your night with a bonfire and live barbeque in the garden. Book now for an unforgettable hillside retreat!",
    price: "7000",
  },
  "1-bhk-apartment-stay": {
    title: "1 BHK Apartment",
    maxguest: 2,
    meta: "Book 1BHK Apartment for an unforgettable Stay in Mukteshwar",
    description: "Stay in a fully-equipped 1BHK apartment at Indus Valley Homestay, Mukteshwar. Perfect for families or groups seeking a peaceful getaway",
    extbed: 1,
    plot: "IVH 09",
    alt:[
      "stay in mukteshwar",
      "mukteshwar hotel"
    ],
    "images": [
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/project/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/8.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/12.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/10.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/9.jpg",
    ],
    summary: "Escape to our 2BHK Nanda Devi View Villa - a perfect blend of modern comfort and breathtaking nature. Wake up to panoramic views, our 2 BHK family room features 2 bedrooms and a separate bathroom with a shower, and living area and a dining area with a kitchenette, a wardrobe, an electric kettle as well as a garden with mountain views. relax in spacious living areas, and indulge in the serenity of your private haven, you can enjoy your night with a bonfire and live barbeque in the garden. Book now for an unforgettable hillside retreat!",
    price: "4000",
  },
  "4-bhk-villa-stay-in-mukteshwar": {
    title: "4 BHK Villa",
    meta: "Perfect 4 BHK Villa for Family Getaways in Mukteshwar",
    description: "Indus Valley Homestay offers a beautiful 4BHK villa in Mukteshwar to stay, perfect for group stays. Relax in comfort with scenic views and full amenities",
    maxguest: 8,
    extbed: 4,
    plot: "IVH 43",
    alt:[
      "4bhk villa for stay",
      "4bhk cottage fro stay"
    ],
    "images": [
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/drone/1.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/2.jpg",
      // "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/2.jpg",
      // "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/12.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/14.jpg",
      // "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/15.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/16.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/7.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/8.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/9.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/10.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/11.jpg",
      // "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/13.jpg",
      // "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/17.jpg",
    ],
    summary: "The 4 BHK is known as Mountain View Villa. This duplex family room features 4 bedrooms. 2 bedrooms are on the ground floor and offer a separate bathroom with a shower, and living area with sofa cum bed and dining area, a wardrobe, an electric kettle and 2 bedrooms are on the upper floor with dining area and a huge balcony with Himalayan mountain view attached with both rooms, 2 separate gardens, and free personal parking. Experience comfort and elegance in this stylish 4BHK Villa, nestled in a serene location, the villa boasts modern amenities, spacious rooms, and a tranquil ambience. Ideal for a relaxing getaway, this well-appointed accommodation ensures a delightful stay for both leisure and business travellers. You can enjoy your night with a bonfire and live barbeque in the garden. Book now for a memorable retreat!",
    price: "12000",
  },
  "1-bhk-villa-for-stay": {
    title: "1 BHK Apartment",
    plot: "IVH 42",
    maxguest: 2,
    meta: "Book a 1 BHK Apartment in Mukteshwar | Indus Valley Homestay",
    description: "Book a cozy 1BHK Apartment at Indus Valley Homestay in Mukteshwar for a relaxing, nature-filled retreat with all the comforts of home",
    extbed: 1,
    alt:[
      "mukteshwar resort",
      "stay near nainital"
    ],
    "images": [
      "https://d6pltnqzug32h.cloudfront.net/cottages/42/1.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/42/2.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/5.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/1.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/42/3.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/42/4.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/42/5.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/42/6.jpg",
      "https://d6pltnqzug32h.cloudfront.net/cottages/42/7.jpg",

    ],
    summary: "Charm meets the mountains in our 1 BHK Nanda Devi View Apartment Snuggle up with nature’s beauty, and wake up to mountain magic. The 1 BHK room features 1 bedroom and 1 bathroom with a shower and free toiletries. The 1 BHK room offers a living area with a common bathroom and a dining area with a kitchenette, a wardrobe, and an electric kettle as well as a garden with mountain views. You can enjoy your night with a bonfire and live barbeque in the garden. Your cosy escape awaits - book now for a view that steals hearts!",
    price: "5000",
  },
}

