import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "./screens/Home";
import About from "./screens/About";
import Blog from "./screens/Blog";
import Dining from "./screens/Dining";
import Nearby from "./screens/Nearby";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import RenderCottage from "./components/render/RenderCottage";
import Notfound from "./screens/Notfound";
import Availability from "./screens/Availability";
import Splash from "./components/Splash";
import IconsFloat from "./components/IconsFloat";
import ScrollToTop from "./components/ScrollToTop";
import BlogRender from "./components/blog/BlogRender"



function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const hasSeenSplash = sessionStorage.getItem("hasSeenSplash");

    if (hasSeenSplash) {
      setIsLoading(false);
    } else {
      const timer = setTimeout(() => {
        setIsLoading(false);
        sessionStorage.setItem("hasSeenSplash", "true");
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      <AnimatePresence>
        {isLoading && (
            <Splash />
        )}
      </AnimatePresence>
      {!isLoading && (
        <BrowserRouter>
          <div className="bgmc">
            <video playsInline autoPlay muted loop id="bgvid" className="videocus">
              <source
                src="https://d2ehq5aws28ia0.cloudfront.net/background+2222.mp4"
                type="video/mp4"
              />
            </video>
            <IconsFloat/>
            <Navbar />
            <ScrollToTop/>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/availability" element={<Availability />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/dinein" element={<Dining />} />
              <Route path="/nearby" element={<Nearby />} />
              <Route path="/cottages/:title" element={<RenderCottage />} />
              <Route path="*" element={<Notfound />} />
              <Route path="/blog/:link" element={<BlogRender />} />
            </Routes>
            <Footer />
          </div>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
