import React from 'react'
import { FaFacebookSquare, FaYoutube, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Mail } from 'lucide-react';

function Footer() {
    return (
        <>
        <footer className='bg-gray-100 w-full p-8 grid grid-cols-1 md:grid-cols-4 gap-8'>
            <div className='w-full h-full flex flex-col gap-5 justify-center items-center'>
                <div className='text-3xl md:text-6xl rounded-md pattaya text-center font-bold text-red-600'>
                <a href='https://theshubhamgroup.com' target='_blank' rel="noreferrer">
                    <img src="https://d6pltnqzug32h.cloudfront.net/footerlogo.png" className='h-52' alt="footer" />
                </a>
                </div>
                <div className='flex gap-4 items-center'>
                    {[
                        { Icon: FaFacebookSquare, link: 'https://facebook.com/IndusValleyHomestay', color: 'text-blue-600' },
                        { Icon: FaInstagram, link: 'https://instagram.com/indusvalleyhomestay', color: 'text-pink-600' },
                        { Icon: FaYoutube, link: 'https://youtube.com/@indusvalleymukteshwar', color: 'text-red-600' },
                        { Icon: FaLinkedin, link: 'https://linkedin.com/company/the-shubham-group1', color: 'text-blue-800' }
                    ].map(({ Icon, link, color }, index) => (
                        <a 
                            key={index} 
                            href={link} 
                            target="_blank" 
                            rel="noreferrer"
                            className={`${color} cursor-pointer hover:scale-110 transition-all duration-300`}
                        >
                            <Icon size={40} />
                        </a>
                    ))}
                </div>
            </div>

            <div className='w-full h-full space-y-6'>
                <div>
                    <h3 className='text-2xl font-bold text-gray-800 mb-3 text-center'>Site Address</h3>
                    <address className='text-gray-600 text-lg text-center not-italic'>
                        Dhanachuli state highway,
                        <br />Sunderkhal, Nainital,
                        <br />Uttarakhand - 263132
                    </address>
                </div>
                <div>
                    <h3 className='text-2xl font-bold text-gray-800 mb-3 text-center'>Corporate Address</h3>
                    <address className='text-gray-600 text-lg text-center not-italic'>
                        315, Block-B, Sector-105, Noida,
                        <br />Uttar Pradesh, Pincode - 201304
                    </address>
                </div>
            </div>

            <div className='w-full h-full'>
                <h3 className='text-2xl font-bold text-gray-800 mb-4 text-center'>Quick Links</h3>
                <nav className='space-y-3'>
                    {[
                        { name: 'About Us', href: '/about' },
                        { name: 'Dine In', href: '/dinein' },
                        { name: 'Blogs', href: '/blogs' },
                        { name: 'Nearby', href: '/nearby' }
                    ].map(({ name, href }, index) => (
                        <p key={index} className='text-center'>
                            <a 
                                href={href} 
                                rel="noreferrer"
                                className='text-gray-600 text-lg hover:text-red-700 transition-colors'
                            >
                                {name}
                            </a>
                        </p>
                    ))}
                </nav>
            </div>

            <div className='w-full h-full'>
                <h3 className='text-2xl font-bold text-gray-800 mb-4 text-center'>Point Of Contact</h3>
                <div className='space-y-3'>
                    {[
                        { type: 'Email', value: 'Mail Us', href: 'mailto:helpdesk@indusvalleyhomestay.com' },
                        { type: 'Phone', value: '97 11 258 989', value2:"IVH Host", href: 'tel:+919711258989' },
                        { type: 'Phone', value: '87 44 989 989', value2:"IVH Helpdesk", href: 'tel:+918744989989' },
                        { type: 'Phone', value: '88 26 278 989', value2:"IVH Complain", href: 'tel:+918826278989' },
                    ].map(({ type, value,value2, href }, index) => (
                        <p key={index} className='text-gray-600 text-lg text-center'>
                            
                            <a 
                                href={href} 
                                target={type === 'Location' ? '_blank' : undefined}
                                rel={type === 'Location' ? 'noopener noreferrer' : undefined}
                                className='hover:text-red-700 transition-colors'
                            >
                                {value2 && (<p className='text-lg font-bold text-gray-800 text-center'>{value2}</p>)}
                                {value === 'Mail Us' ? <span className='flex justify-center items-center gap-3'><Mail /> {value}</span> : <>{value}</>}

                            </a>
                        </p>
                    ))}
                </div>
            </div>
        </footer>

        <div className='w-full py-5 border-t-2 border-gray-300 bg-gray-100'>
            <p className='text-center text-gray-600'>
                Copyright © {new Date().getFullYear()} Indus Valley Homestay. All Rights Reserved
            </p>
        </div>
        </>
    )
}

export default Footer
