import React from 'react';

const Calendar = ({
    currentMonth,
    changeMonth,
    calendarDays,
    bookingData,
    hoverDate,
    isCheckIn,
    formatDate,
    isDateInRange,
    handleCheckInSelect,
    handleCheckOutSelect,
    setHoverDate
}) => {
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    return (
        <div className="absolute z-50 bg-white shadow-lg rounded-lg p-4 w-[300px]">
            {/* Calendar Header */}
            <div className="flex justify-between items-center mb-4">
                <button
                    onClick={() => changeMonth(-1)}
                    className="px-2 py-1 bg-gray-200 rounded"
                >
                    ←
                </button>
                <div className="font-bold">
                    {monthNames[currentMonth.getMonth()]} {currentMonth.getFullYear()}
                </div>
                <button
                    onClick={() => changeMonth(1)}
                    className="px-2 py-1 bg-gray-200 rounded"
                >
                    →
                </button>
            </div>

            {/* Calendar Body */}
            <>
                <div className="grid grid-cols-7 text-center">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                        <div key={day} className="font-bold text-sm">{day}</div>
                    ))}
                    {calendarDays.map((day, index) => {
                        const isCheckInSelected =
                            bookingData.checkIn &&
                            day &&
                            day.toDateString() === bookingData.checkIn.toDateString();

                        const isCheckOutSelected =
                            bookingData.checkOut &&
                            day &&
                            day.toDateString() === bookingData.checkOut.toDateString();

                        const isInRange = day && isDateInRange(day);

                        return (
                            <div
                                key={index}
                                className={`p-2 cursor-pointer
                                    ${!day ? 'text-gray-300' : 'hover:bg-red-100'}
                                    ${isCheckInSelected ? 'bg-red-600 text-white' : ''}
                                    ${isCheckOutSelected ? 'bg-red-600 text-white' : ''}
                                    ${isInRange ? 'bg-red-100' : ''}
                                    ${day && day < new Date() ? 'text-gray-400' : ''}
                                `}
                                onMouseEnter={() => {
                                    if (day && day >= new Date() && bookingData.checkIn && !bookingData.checkOut) {
                                        setHoverDate(day);
                                    }
                                }}
                                onMouseLeave={() => {
                                    setHoverDate(null);
                                }}
                                onClick={() => {
                                    if (day && day >= new Date()) {
                                        day && (isCheckIn ? handleCheckInSelect(day) : handleCheckOutSelect(day));
                                    }
                                }}
                            >
                                {day ? day.getDate() : ''}
                            </div>
                        );
                    })}
                </div>
                {!isCheckIn && bookingData.checkIn && (
                    <div className="text-center text-sm mt-2 text-gray-600">
                        Check-in: {formatDate(bookingData.checkIn)}
                    </div>
                )}
            </>
        </div>
    );
};

export default Calendar;