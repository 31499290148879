import React from 'react';
import { Helmet } from 'react-helmet';
import Blogcard from "../components/blog/Blogcard"

function Blog() {
    const blogPosts = [
      {
        id: 1,
        title: 'Discover the Best Homestay in Mukteshwar ',
        excerpt: 'Indus Valley Homestay',
        date: 'Dec 26, 2024',
        readTime: '7 min read',
        image: 'https://d6pltnqzug32h.cloudfront.net/Blogs/blog1.jpg',
        link:'/blog/finding-the-best-homestay-in-mukteshwar',
        alt:'homestay in mukteshwar - indus valley homestay',
      },
    ];

    return (
        <>
            {/* Header Section */}
            <div className='w-full h-[40vh] md:h-[380px] flex flex-col' style={{
                backgroundImage: `url("https://d2ehq5aws28ia0.cloudfront.net/himalayan/1.jpg")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                {/* Helmet for SEO */}
                <Helmet>
                    <title>Mukteshwar Travel Guides, Tips, and Updates | Indus Valley Homestay Blogs</title>
                    <meta
                        name="description"
                        content="Read insightful travel blogs on Mukteshwar, local attractions, and the best things to do in the region. Explore more with Indus Valley Homestay Blogs"
                    />
                </Helmet>
                <div className=' h-full w-full items-center justify-center flex flex-col'>
                    <div className='w-full h-full flex flex-col items-center justify-center'>
                        <div className='text-center text-2xl mt-[70px] instrument text-white
            '>
                            <span className='md:text-6xl text-4xl lg:text-8xl pattaya'>Blogs</span>
                        </div>
                    </div>

                </div>
            </div>
            <Blogcard blogPosts={blogPosts}/>
        </>
    );
}

export default Blog;
